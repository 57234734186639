.productdisplay {
    display: flex;
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 100px;
  }
  
  .productdisplay-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .productdisplay-img-list img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .productdisplay-main-img {
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: contain;
  }
  
  .productdisplay-right {
    flex: 1;
    padding: 20px;
  }
  
  .productdisplay-right h1 {
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
  }
  
  .productdisplay-right-star {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
  }
  
  .productdisplay-right-star img {
    width: 20px;
    height: 20px;
  }
  
  .productdisplay-right-star p {
    font-size: 14px;
    color: #888;
  }
  
  .productdisplay-right-prices {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .productdisplay-right-price-old {
    font-size: 18px;
    text-decoration: line-through;
    color: grey;
  }
  
  .productdisplay-right-price-new {
    font-size: 22px;
    font-weight: bold;
    color: #e74c3c;
  }
  
  .productdisplay-right-description {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.5;
    color: #555;
  }
  
  .productdisplay-right-size h1 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .productdisplay-right-size div {
    display: inline-block;
    padding: 8px 12px;
    border: 1px solid #ddd;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    transition: background-color 0.2s ease;
  }
  
  .productdisplay-right-size div:hover {
    background-color: #f0f0f0;
  }
  
  button {
    background-color: #3498db;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
  }
  
  button:hover {
    background-color: #2980b9;
  }
  
  .productdisplay-right-category {
    font-size: 14px;
    margin-top: 10px;
    color: #777;
  }
  
  .productdisplay-right-category span {
    font-weight: bold;
    color: #333;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .productdisplay {
      flex-direction: column;
      gap: 40px;
      position: relative;
      top:40px;
    }
  
    .productdisplay-main-img {
      max-width: 100%;
    }
  }
  @media (max-width: 400px) {
    .productdisplay {
      flex-direction: column;
      gap: 40px;
    }
  
    .productdisplay-main-img {
      max-width: 100%;
    }
  }
  
  