/* NewsLetter.css */

/* Import fonts from Google Fonts or include them in your project */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@400;700&display=swap');

.newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background: linear-gradient(90deg, #f7f7f7 0%, #ffffff 100%);
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.newsletter h1 {
    font-size: 2.5rem; /* Large font size for the heading */
    font-weight: 700;
    color: #2c3e50; /* Dark color to match the rest of the theme */
    margin-bottom: 10px;
    font-family: 'Playfair Display', serif; /* Elegant serif font */
}

.newsletter p {
    font-size: 1.2rem; /* Slightly smaller font size for the description */
    color: #34495e; /* Darker text color */
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif; /* Clean sans-serif font */
}

.newsletter input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 30px;
    width: 100%;
    max-width: 400px; /* Max width for better responsiveness */
    margin-right: 10px;
    box-sizing: border-box;
}

.newsletter button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #ffffff;
    background-color: #e94e77; /* Bright button color to match the theme */
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.newsletter button:hover {
    background-color: #d43f66; /* Darker shade for hover effect */
}
