.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-color: #ffe4e1; /* Soft light pink background */
}



.hero-content {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 30px;
    max-width: 80%;
    background: rgba(255, 255, 255, 0.9); /* Slightly white background with opacity */
    border: 1px solid #ffb6c1; /* Light pink border */
    border-radius: 12px; /* Rounded corners for the box */
    color: #333; /* Dark text color for better readability */
}

.hero-content h2 {
    font-size: 48px;
    margin-bottom: 20px;
    font-family: 'Georgia', serif; /* Elegant font style */
    font-weight: 700;
}

.hero-content p {
    font-size: 24px;
    margin-bottom: 30px;
    font-family: 'Arial', sans-serif; /* Clean and readable font */
    font-weight: 300;
}

.hero-button {
    display: inline-flex;
    align-items: center;
    background-color: #ff69b4; /* Vibrant pink button */
    color: #fff;
    padding: 15px 30px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    font-family: 'Arial', sans-serif; /* Matching font style */
    font-weight: 600;
    transition: background-color 0.3s ease;
    border: none;
}

.hero-latest-btn:hover {
    background-color: #ff1493; /* Darker pink on hover */
}

.hero-latest-btn img {
    margin-left: 10px;
    width: 20px;
    height: 20px;
}

@media(max-width:882px){
    .hero-content{
        top:5%;
        padding:5px;
        margin-top:200px;
        width:400px;
        
    }
    .hero-content h2 {
        font-size: 40px;

    }

}

@media(max-width:480px){
    .hero-content{
        position: absolute;
        top:20px;
    }
}
