.item {
    text-align: center;
}

.image-container {
    width: 500px; /* Desired width of the image */
    height: 800px; /* Desired height of the image */
    overflow: hidden; /* Ensure overflow is hidden to crop large images */
    position: relative;
    border-radius: 10px; /* Optional: Rounded corners */
    margin-bottom: 10px; /* Space between the image and text */
}

.item-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Crop large images to fit the container */
    object-position: center; /* Center the image within the container */
    max-width: 100%; /* Ensure small images scale up to fit the container */
    max-height: 100%; /* Ensure small images scale up to fit the container */
}
@media(max-width:1280px){
    .item{
        width:220px;
        font-size:14px;

    }
    .item img{
        width:220px;
    }
    .item-price-old{
        font-size:14px;
    }
}
@media(max-width:1024px){
    .item{
        width:170px;
        font-size: 13px;
    }
    .item img{
        width:170px;
    }
    .item-price-old{
        font-size:14px;
    }
}

@media(max-width:800px){
    .item{
        width:120px;
        font-size: 12px;
    }
    .item img{
        width:120px;
    }
    .item-price-old{
        font-size:12px;
    }
    
}
@media(max-width:500px){
    .item{
        width:160px;
    }
    .item img{
        width:160px;
    }
}
