

.navbar {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    box-shadow: 0 1px 3px -2px black;
    background-color: white;
    position: absolute;
    top:100px;
    left: 50%; /* Add this */
    transform: translate(-50%, -50%); /* Add this */
    width: calc(100% - 100px); 
    margin: 0 auto; 
}

.nav-logo {
    align-items: center;
    display: flex;
    gap: 10px;
}

.nav-menu {
    display: flex;
    list-style: none;   
    gap: 50px;
    align-items: center;
}
.nav-cart-login button:hover {
    background-color: #f5b7b1; /* Darker pink color on hover */
}

.nav-menu li {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 3px;
    justify-content: center;
}

.nav-cart-login {
    display: flex;
    gap: 10px;
    align-items: center;
}
.nav-cart-login button{
    background-color:#ffe4e1;
    color:black;
}


.nav-cart-count {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -17px;
    margin-left: -22px;
    border-radius: 11px;
    background-color: red;
    color: white;
}



.nav-menu li:hover .dropdown-content {
    display: flex; /* Changed to flex for proper alignment */
    opacity: 1;
    transform: translateY(0);
}

.nav-menu li .dropdown-content img {
    width: 200px; /* Fixed width for images */
    height: auto;
    border-radius: 4px;
    margin-right: 10px; /* Space between image and text */
}

.dropdown-content p {
    margin: 0;
    color: #333;
    font-size: 16px;
}

.nav-menu hr {
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: grey;
}

.nav-dropdown {
    display: none;
    cursor: pointer;
}



@media (max-width: 882px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
        top:20%;
    }

    .nav-dropdown {
        display: block; /* Show the dropdown icon on mobile screens */
    }

    .nav-menu {
        display: none; /* Hide the menu by default on mobile */
        flex-direction: column;
        gap: 10px;
        width: 100%;
        padding: 10px;
        background-color: white;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 180px;
        left: 0;
        z-index: 1000;
    }

    .nav-menu-visible {
        display: flex; /* Show menu when toggled */
        flex-direction: row;
    }

    .nav-menu li {
        text-align: center;
        padding: 10px 0; /* Add padding to menu items */
        border-bottom: 1px solid #eee; /* Add bottom border to items */
    }

    .nav-menu li:last-child {
        border-bottom: none; /* Remove border from last item */
    }

    .nav-menu li .dropdown-content {
        width: 100%; /* Adjust dropdown width for mobile */
        padding: px;
    }

    .nav-menu li .dropdown-content img {
        width: 150px; /* Adjust image width for mobile */
    }

}

@media (max-width: 528px) {
    .navbar {
        top:20px;
        display: flex;
        flex-direction: row;
        position: fixed; /* Fixed positioning */
        width: 100%; /* Full width */
        background-color: white;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* Shadow for separation */ /* Space items between the sides */
        z-index: 1000; /* Ensure it is on top */
        height: 60px; /* Fixed height for compact look */
    }

    .nav-logo {
        display: none; /* Hide the logo */
    }

    .nav-menu {
        top:50px;
        display:flex;
        width:100%;
        flex-direction: row;
        justify-content: space-around; /* Space items evenly */
        align-items: center; /* Center items vertically */
        height: 100%; /* Full height for proper alignment */
        font-size: 14px; /* Smaller font size for compactness */
    }

    .nav-menu li {
        padding: 5px; /* Reduced padding for smaller height */
        text-align: center;
    }

    .nav-menu hr {
        display: none; /* Hide underline on mobile */
    }

    .nav-dropdown {
        display: none; /* Hide the dropdown icon */
    }

    .nav-menu li .dropdown-content {
        display: none; /* Ensure dropdown content is hidden */
    }
}